<template>
  <!--:src="barImage"-->
  <v-navigation-drawer
    id="core-navigation-drawer"
    class="drawer"
    v-model="drawer"
    :dark="barColor !== 'rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.7)'"
    :expand-on-hover="expandOnHover"
    :right="$vuetify.rtl"
    mobile-break-point="960"
    app
    width="260"
    mini-variant-width="80"
    v-bind="$attrs"

  >
    <template v-slot:img="props" >
      <v-img
        :gradient="`to bottom, ${barColor}`"
        v-bind="props"
      />
    </template>

    <!--<v-list-item two-line>-->
    <!--<v-list-item-content>-->
    <!--<v-list-item-title class="text-uppercase font-weight-regular display-2">-->
    <!--<span class="logo-mini">{{ $t('cx') }}</span>-->
    <!--&lt;!&ndash;<span class="logo-normal">{{ $t('cms') }}</span>&ndash;&gt;-->
    <!--</v-list-item-title>-->
    <!--</v-list-item-content>-->
    <!--</v-list-item>-->

    <v-divider class="mb-1"/>
    <!--头像-->
    <v-list
      dense
      nav
    >
      <base-item-group :item="profile"/>
    </v-list>
    <v-divider class="mb-2"/>

    <v-list
      expand
      nav
    >
      <!-- Style cascading bug  -->
      <div/>

      <template v-for="(item, i) in computedItems">
        <base-item-group
          v-if="item.children"
          :key="`group-${i}`"
          :item="item"
        >
          <!--  -->
        </base-item-group>

        <base-item
          v-else
          :key="`item-${i}`"
          :item="item"
        />
      </template>
      <p @click="toIframe" class="extraItemContainer" v-show="showAdobeIframe">
        <span class="extraItem" style="margin-left: 54px">CAFE BONAPP</span>
      </p>
      <!--<base-item-->
      <!--  :item="adobeItem"-->
      <!--  @change="toIframe"-->
      <!--/>-->
      <!-- Style cascading bug  -->
      <div/>
    </v-list>


    <div v-if="client=='Applied Materials'&&bottom==true" class="bottom" ref="bottom">
      <span class="link" @click="openTab(copyRight.link)"  style="float: unset">{{ copyRight.text }}</span>&nbsp;
      <span class="link" v-for="(link, i) in links" @click="openTab(link.href)"  style="float: unset">{{ link.text }}</span>
      <p>
        <span class="link" @click="clickSupport">Support</span>
        <span style="margin: 0 10px"> | </span>
        <span>V2.0.0</span>
      </p>
    </div>
    <div v-else>
      <div class="bottom" ref="bottom" v-if="bottom==true">
        <a :href="copyRight.link">{{ copyRight.text }}</a>
        <a v-for="(link, i) in links" :href="link.href">{{ link.text }}</a>
        <span>V2.0.0</span>
      </div>
    </div>
  </v-navigation-drawer>
</template>

<script>
// Utilities
import {mapState, mapGetters, mapMutations} from 'vuex';
import axios from "axios";

export default {
  name: 'DashboardCoreDrawer',
  props: {
    expandOnHover: {
      type: Boolean,
      default: false
    }
  },

  watch: {
    $route() {
      if (localStorage.getItem("client") == 'WarnerMedia') {
        this.checkTheUrlWarnerMedia();
      }
      if (localStorage.getItem("client") == 'adobe') {
        this.checkTheUrlAdobe();
      } else {
        this.checkTheUrl();
      }
    },
    getAvator(newVal, oldVal) {
      this.avatorImg = "files/" + localStorage.image;
    },
    getConfidanielDomain(newVal, oldVal){
      console.log('111111','sunsun123',newVal,oldVal,JSON.parse(localStorage.config));
      if(JSON.parse(localStorage.config).features.indexOf("globalDirectory") > -1 || JSON.parse(localStorage.config).features.indexOf("global2Directory") > -1){
        
      // this.items.map((i,k)=>{
      //   console.log(i);
      //   console.log(k);
      // })
        if(newVal == true){
          console.log('sun1',localStorage.meetingIdMap);
          
          if (localStorage.meetingIdMap == '' || localStorage.meetingIdMap == undefined) {
            let isHaveDirectory = false;
              this.items.forEach((i)=>{
                if(i.title=='Employee Directory'){
                  isHaveDirectory = true;
                  return
                }
              })
              if(isHaveDirectory == false){
                let obj = {
                  img: require('@/assets/images/drawer/directory_grey.png'),
                  title: 'Employee Directory',
                  to: 'globalDirectory'
                };
                this.items.splice(1,0,obj);
              }
          }
       
        
        }else{
          console.log('sun2');
          let isHaveDirectory = false;
          let isHaveDirectoryNum = 0;
          this.items.forEach((i,k)=>{
            if(i.title=='Employee Directory'){
              isHaveDirectory = true;
              isHaveDirectoryNum = k;
              return;
            }
          })
          if(isHaveDirectory == true){
            this.items.splice(isHaveDirectoryNum, 1);
          }
        }
      }
      
    },
    getUserAvator(newVal, oldVal){
      console.log(newVal, oldVal);
      this.avatorImg = "files/" + newVal;
      console.log(this.avatorImg,'才才');
    }
  },
  // watch: {
  //   '$vuetify.breakpoint.smAndDown'(val) {
  //     this.$emit('update:expandOnHover', !val)
  //   },
  //
  // },
  data: () => ({
    mecGroupLabel:'Campus',
    firstName: "",
    lastName: "",
    showAdobeIframe: false,
    adobeItem: {},
    avatorImg: '',
    bottom: true,
    links: [],
    // links: [
    //   // {
    //   //   href: '',
    //   //   text: 'PRIVACY'
    //   // },
    //   // {
    //   //   href: '',
    //   //   text: 'CONTACT SUPPORT'
    //   // },
    //   {
    //     href: '',
    //     text: 'Terms of Use'
    //   },
    //   // {
    //   //   href: '#',
    //   //   text: 'V1.0.0'
    //   // }
    // ],
    copyRight: {
      'link': '',
      'text': ''
    },
    items: [
      // {
      //   img: require('@/assets/images/drawer/meeting_selector.png'),
      //   title: this.mecGroupLabel+' Selector',
      //   to: 'mec'
      // },
      // {
      //   img: require('@/assets/images/drawer/Settings.png'),
      //   title: 'Settings',
      //   to: 'setting'
      // },
    ],
    client:""
  }),
  methods: {
    ...mapMutations('meetingId', ["setMeetingId"]),
    ...mapMutations('confidanielDomain', ["setUserConfidanielDomain"]),
    ...mapMutations('mecToSetting', ["setMecToSetting"]),
    mapItem(item) {
      return {
        ...item,
        children: item.children ? item.children.map(this.mapItem) : undefined,
        title: this.$t(item.title)
      }
    },
    getMecGroupLabel() {
      let config = JSON.parse(localStorage.getItem("config"));
      if (config.campus&&config.campus.mecGroupLabel) {
        this.mecGroupLabel = config.campus.mecGroupLabel;
      }
    },
    cloroxAddGetHelp(){
      let config = JSON.parse(localStorage.config);
      let obj = {
        img: require('@/assets/images/drawer/get-help.png'),
        title: 'Get Help',
        // href: "https://cloroxcampus.com/files/i5kge9ui0sffclhd82qqzhn9/Pleasanton_Support.html"
        href: "https://cloroxcampus.com/files/i5kge9ui0sffclhd82qqzhn9/Pleasanton_Support_Web.html"
        // href: config.terms.supportLink
      }
      this.items.push(obj);
    },
    checkTheUrl() {
      if (this.$route.name === 'mec') {
        this.items = [
          {
            img: require('@/assets/images/drawer/meeting_selector.png'),
            // title: this.mecGroupLabel+' Selector',
            title: this.mecGroupLabel+' Selector',
            to: 'mec'
          },
          // {
          //   img: require('@/assets/images/drawer/Directory.png'),
          //   title: 'Employee Directory',
          //   to: 'globalDirectory'
          // },
          {
            img: require('@/assets/images/drawer/Settings.png'),
            title: 'Settings',
            to: 'setting'
          },
        ];
        if (localStorage.getItem("client") == 'Clorox') {
          // let config = JSON.parse(localStorage.config);
          // let obj = {
          //   img: require('@/assets/images/drawer/get-help.png'),
          //   title: 'Get Help',
          //   href: "https://cloroxcampus.com/files/i5kge9ui0sffclhd82qqzhn9/Pleasanton_Support.html"
          //   // href: config.terms.supportLink
          // }
          // this.items.push(obj);
          this.cloroxAddGetHelp();
        }
        this.setMecToSetting(true);
        // localStorage.meetingIdMap = '';
        // console.log(this.getMecToSetting,121212);
      }
      else {
        if (!localStorage.meetingIdMap) {
          this.items = [
            {
              img: require('@/assets/images/drawer/meeting_selector.png'),
              title: this.mecGroupLabel+' Selector',
              to: 'mec'
            },
            // {
            //   img: require('@/assets/images/drawer/Directory.png'),
            //   title: 'Employee Directory',
            //   to: 'globalDirectory'
            // },
            {
              img: require('@/assets/images/drawer/Settings.png'),
              title: 'Settings',
              to: 'setting'
            },
          ];
          if (localStorage.getItem("client") == 'Clorox') {
            // let config = JSON.parse(localStorage.config);
            // let obj = {
            //   img: require('@/assets/images/drawer/get-help.png'),
            //   title: 'Get Help',
            //   href: config.terms.supportLink
            // }
            // this.items.push(obj);
            this.cloroxAddGetHelp();
          }
        }
        else {
          console.log('2222222222222222222222222222222222222222222222222222',);
          this.items = [
            {
              img: require('@/assets/images/drawer/meeting_selector.png'),
              title: this.mecGroupLabel+' Selector',
              to: 'mec'
            },
            {
              img: require('@/assets/images/drawer/Spaces.png'),
              title: 'Spaces',
              to: 'spaces'
            },
            {
              img: require('@/assets/images/drawer/Bookings.png'),
              title: 'Bookings',
              to: 'booking'
            },
            // {
            //   img: require('@/assets/images/drawer/Directory.png'),
            //   title: 'People',
            //   to: 'directory'
            // },
            // {
            //   img: require('@/assets/images/drawer/Directory.png'),
            //   title: 'Employee Directory',
            //   to: 'globalDirectory'
            // },
            {
              img: require('@/assets/images/drawer/Settings.png'),
              title: 'Settings',
              to: 'setting'
            },
          ];
          // Hard code  IMD 的 Participant Campus不显示People
          if(localStorage.getItem("client") == 'IMD Campus'&&localStorage.meetingIdMap=='io0545wrxx5u13z06gfgbiv7'){
            let length = this.items.length;
            for (let i=0;i<length;i++){
              let nav = this.items[i];
              if(nav.title=='People'){
                console.log('nav:',nav);
                this.items.splice(i,1)
                break
              }
            }
          }

          if (localStorage.getItem("client") == 'Clorox') {
            // let config = JSON.parse(localStorage.config);
            // let obj = {
            //   img: require('@/assets/images/drawer/get-help.png'),
            //   title: 'Get Help',
            //   href: config.terms.supportLink
            // }
            // this.items.push(obj);
            this.cloroxAddGetHelp();
          }
          this.setMecToSetting(false);
        }
      }
      this.checkConfidanielDomain();
    },
    checkTheUrlWarnerMedia() {
      if (this.$route.name === 'mec') {
        this.items = [
          {
            img: require('@/assets/images/drawerWM/meeting_selector.png'),
            title: this.mecGroupLabel+' Selector',
            to: 'mec'
          },
          // {
          //     img: require('@/assets/images/drawer/Directory.png'),
          //     title: 'Employee Directory',
          //     to: 'globalDirectory'
          //   },
          {
            img: require('@/assets/images/drawerWM/Settings.png'),
            title: 'Settings',
            to: 'setting'
          },
        ];
        this.setMecToSetting(true);
        // localStorage.meetingIdMap = '';
        // console.log(this.getMecToSetting,121212);
      }
      else {
        if (!localStorage.meetingIdMap) {
          this.items = [
            {
              img: require('@/assets/images/drawerWM/meeting_selector.png'),
              title: this.mecGroupLabel+' Selector',
              to: 'mec'
            },
            // {
            //   img: require('@/assets/images/drawer/Directory.png'),
            //   title: 'Employee Directory',
            //   to: 'globalDirectory'
            // },
            {
              img: require('@/assets/images/drawerWM/Settings.png'),
              title: 'Settings',
              to: 'setting'
            },
          ];
        } else {
          this.items = [
            {
              img: require('@/assets/images/drawerWM/meeting_selector.png'),
              title: this.mecGroupLabel+' Selector',
              to: 'mec'
            },
            {
              img: require('@/assets/images/drawerWM/icon_Spaces.png'),
              title: 'Spaces',
              to: 'spaces'
            },
            {
              img: require('@/assets/images/drawerWM/icon_reservation.png'),
              title: 'Reservations',
              to: 'booking'
            },
            // {
            //   img: require('@/assets/images/drawerWM/people.png'),
            //   title: 'People',
            //   to: 'directory'
            // },
            // {
            //   img: require('@/assets/images/drawer/Directory.png'),
            //   title: 'Employee Directory',
            //   to: 'globalDirectory'
            // },
            {
              img: require('@/assets/images/drawerWM/Settings.png'),
              title: 'Settings',
              to: 'setting'
            },
          ];
          this.setMecToSetting(false);
        }
      }
      this.checkConfidanielDomain();
    },
    checkTheUrlAdobe() {
      if (this.$route.name === 'mec') {
        this.showAdobeIframe = false;
        this.items = [
          {
            img: require('@/assets/images/drawerWM/meeting_selector.png'),
            title: this.mecGroupLabel+' Selector',
            to: 'mec'
          },
          // {
          //   img: require('@/assets/images/drawer/Directory.png'),
          //   title: 'Employee Directory',
          //   to: 'globalDirectory'
          // },
          {
            img: require('@/assets/images/drawerWM/Settings.png'),
            title: 'Settings',
            to: 'setting'
          },
        ];
        this.setMecToSetting(true);
        // localStorage.meetingIdMap = '';
        // console.log(this.getMecToSetting,121212);
      } else {
        if (!localStorage.meetingIdMap) {
          this.showAdobeIframe = false;
          this.items = [
            {
              img: require('@/assets/images/drawerWM/meeting_selector.png'),
              title: this.mecGroupLabel+' Selector',
              to: 'mec'
            },
            // {
            //   img: require('@/assets/images/drawer/Directory.png'),
            //   title: 'Employee Directory',
            //   to: 'globalDirectory'
            // },
            {
              img: require('@/assets/images/drawerWM/Settings.png'),
              title: 'Settings',
              to: 'setting'
            },
          ];
        } else {
          let condition = localStorage.meetingIdMap == 'btj07otfpg8hipk2gm7cxwke' || localStorage.meetingIdMap == '8kaluglhjd9wotbvsrw60556' || localStorage.meetingIdMap == 'zxru4ct5p5j50b349r39jpcw' || localStorage.meetingIdMap == 'mw0iica3bzoetfb5t5c7o0qf';
          if (condition) {
            this.showAdobeIframe = true;
          } else {
            this.showAdobeIframe = false;
          }
          this.items = [
            {
              img: require('@/assets/images/drawerWM/meeting_selector.png'),
              title: this.mecGroupLabel+' Selector',
              to: 'mec'
            },
            {
              img: require('@/assets/images/drawerWM/icon_Spaces.png'),
              title: 'Spaces',
              to: 'spaces'
            },
            {
              img: require('@/assets/images/drawerWM/icon_reservation.png'),
              title: 'Reservations',
              to: 'booking'
            },
            // {
            //   img: require('@/assets/images/drawerWM/people.png'),
            //   title: 'People',
            //   to: 'directory'
            // },
            // {
            //   img: require('@/assets/images/drawer/Directory.png'),
            //   title: 'Employee Directory',
            //   to: 'globalDirectory'
            // },
            {
              img: require('@/assets/images/drawerWM/Settings.png'),
              title: 'Settings',
              to: 'setting'
            },
          ];
          this.setMecToSetting(false);
        }
      }
      this.checkConfidanielDomain();
    },
    toIframe() {
      window.open("http://us.cafebonappetit.io/baweb/main")
    },
    clickSupport(){
      if (localStorage.getItem("client") == 'Applied Materials') {
        window.open("https://amat.service-now.com/help")
      }
      else{
        window.open("https://usersupport.inpixon.com/hc/en-us/requests/new?ticket_form_id=360001876312&tf_360044921651=AMATSmartCampus")
      }
    },
    openTab(link){
      console.log("==== link=====",link)
      window.open(link)
    },
    checkConfidanielDomain(){
      console.log('this.items：',this.items);
      console.log('this.confg:', JSON.parse(localStorage.config),localStorage.userConfidanielDomain);
      console.log('confidanielDomain：',this.$store.state.confidanielDomain,JSON.parse(localStorage.config).features,JSON.parse(localStorage.config).features.indexOf("globalDirectory") > -1,"globalDirectory" in JSON.parse(localStorage.config).features);
      if(JSON.parse(localStorage.config).features.indexOf("globalDirectory") > -1 || JSON.parse(localStorage.config).features.indexOf("global2Directory") > -1){
        console.log('123234');
        if(this.$store.state.confidanielDomain == true){
          // console.log('1111111',);
          let obj = {};
          if(localStorage.meetingIdMap && localStorage.meetingIdMap != ''){
            obj = {
              img: require('@/assets/images/drawer/directory_meeting.png'),
              title: 'People',
              to: 'globalDirectory'
            };
            this.items.splice(2,0,obj);
          }else{
            obj = {
              img: require('@/assets/images/drawer/directory_grey.png'),
              title: 'Employee Directory',
              to: 'globalDirectory'
            };
            this.items.splice(1,0,obj);
          }
        
          
        }else{
          // console.log('2222222',);
          if(localStorage.meetingIdMap && localStorage.meetingIdMap != ''){
            // let obj = {
            //   img: require('@/assets/images/drawer/directory_meeting.png'),
            //   title: 'People',
            //   to: 'directory'
            // };
            let obj = {
              img: require('@/assets/images/drawer/directory_meeting.png'),
              title: 'People',
              to: 'globalDirectory'
            };
            this.items.splice(2,0,obj);
          }else{
            let arr = this.items;
            for (let i = 0; i < arr.length; i++) {
              // console.log(this.items[i].to,'ashen111');
              if (arr[i].to == 'globalDirectory') {
                // console.log('hahhhh',this.items[i].to );
                  arr.splice(i, 1);
                  i--;
              }
            }
            // console.log(arr,'sun Arr',arr.length);
            this.items = arr;
            // console.log(this.items.length,'ashen');
          }
        }
      }else{
        if(localStorage.meetingIdMap && localStorage.meetingIdMap != ''){
          let obj = {
            img: require('@/assets/images/drawer/Directory.png'),
            title: 'People',
            to: 'directory'
          };
          this.items.splice(-1,0,obj);
        }else{
          let arr = this.items;
          for (let i = 0; i < arr.length; i++) {
            if (arr[i].to == 'globalDirectory') {
                arr.splice(i, 1);
                i--;
            }
          }
          this.items = arr;
        }
      }
    }
   

  },
  computed: {
    ...mapState(['barColor', 'barImage']),
    ...mapGetters('meetingId', ['getMeetingId']),
    ...mapGetters('mecToSetting', ['getMecToSetting']),
    ...mapGetters('avator', ['getAvator']),

    drawer: {
      get() {
        return this.$store.state.drawer
      },
      
      set(val) {
        console.log(val);
        this.bottom = val;
        this.$store.commit('SET_DRAWER', val)
      }
    },
    computedItems() {
      return this.items.map(this.mapItem)
    },
    profile() {
      return {
        avatar: true,
        group: '',
        // title: this.$t('Admin'),
        title: localStorage.userName,
        img: this.avatorImg,
        // img:"files/"+localStorage.image,
        children: [
          // {
          //   href: '',
          //   title: this.$t('my-profile')
          // },
          // {
          //   to: '',
          //   title: this.$t('edit-profile')
          // },
          // {
          //   to: '',
          //   title: this.$t('settings')
          // },
          {
            img: require('@/assets/images/drawerWM/meeting_selector.png'),
            to: localStorage.meetingIdMap ? `pages/loginEmail` : 'pages/loginEmail',
            title: this.$t('Logout')
          }
        ]
      }
    },
    getConfidanielDomain(){
      return this.$store.state.confidanielDomain
    },
    getUserAvator(){
      return this.getAvator;
    },
  },
  created() {
    this.client = localStorage.getItem("client");
    this.getMecGroupLabel();
    this.avatorImg = "files/" + localStorage.image;
   
    // 图标
    if (localStorage.getItem("client") == 'WarnerMedia') {
      this.items = [
        {
          img: require('@/assets/images/drawerWM/meeting_selector.png'),
          title: this.mecGroupLabel+' Selector',
          to: 'mec'
        },
        // {
        //   img: require('@/assets/images/drawer/Directory.png'),
        //   title: 'Employee Directory',
        //   to: 'globalDirectory'
        // },
        {
          img: require('@/assets/images/drawerWM/Settings.png'),
          title: 'Settings',
          to: 'setting'
        },
      ]
    } else {
      console.log('mecGroupLabel:',this.mecGroupLabel);
      this.items = [
        {
          img: require('@/assets/images/drawer/meeting_selector.png'),
          // title: this.mecGroupLabel+' Selector',
          title: this.mecGroupLabel+' Selector',
          to: 'mec'
        },
        // {
        //   img: require('@/assets/images/drawer/Directory.png'),
        //   title: 'People',
        //   to: 'globalDirectory'
        // },
        // {
        //   img: require('@/assets/images/drawer/Directory.png'),
        //   title: 'Employee Directory',
        //   to: 'globalDirectory'
        // },
        {
          img: require('@/assets/images/drawer/Settings.png'),
          title: 'Settings',
          to: 'setting'
        },
      ]
    }
    // 底部文字
    if (localStorage.getItem("client") == 'NatWest') {
      this.links = [
        {
          href: '',
          text: 'Support'
        }
      ]
    } else {
      this.links = [
        {
          href: '',
          text: 'Terms of Use'
        }
      ]
    }
    if (localStorage.getItem("client") == 'WarnerMedia') {
      this.checkTheUrlWarnerMedia()
    }
    if (localStorage.getItem("client") == 'adobe') {
      this.checkTheUrlAdobe();
      this.adobeItem = {
        img: require('@/assets/images/drawer/Settings.png'),
        title: 'iframe2',
      };
    } else {
      this.checkTheUrl();
    }
    
    
    
    
    let config = JSON.parse(localStorage.config);
    if (config.terms) {
      // this.links[0].href = config.terms.appPrivacyPolicyLink;
      // this.links[1].href = config.terms.supportLink;
      this.links[0].href = config.terms.link;
      this.copyRight.link = config.terms.copyright.link;
      this.copyRight.text = config.terms.copyright.text;
    }
    this.setMeetingId(localStorage.meetingIdMap);
  },


}
</script>

<style lang="sass">
@import '~vuetify/src/styles/tools/_rtl.sass'
/*#core-navigation-drawer::-webkit-scrollbar*/

#core-navigation-drawer
  ::-webkit-scrollbar
    display: none

  &.v-navigation-drawer--mini-variant
    .v-list-item
      justify-content: flex-start !important

    .v-list-group--sub-group
      display: block !important

  .v-list-group__header.v-list-item--active:before
    opacity: .24

  .v-list-item
    &__icon--text,
    &__icon:first-child
      justify-content: center
      text-align: center
      width: 20px

      +ltr()
        margin-right: 24px
        margin-left: 12px !important

      +rtl()
        margin-left: 24px
        margin-right: 12px !important

  .v-list--dense
    .v-list-item
      &__icon--text,
      &__icon:first-child
        margin-top: 10px

  .v-list-group--sub-group
    .v-list-item
      +ltr()
        padding-left: 8px

      +rtl()
        padding-right: 8px

    .v-list-group__header
      +ltr()
        padding-right: 0

      +rtl()
        padding-right: 0

      .v-list-item__icon--text
        margin-top: 19px
        order: 0

      .v-list-group__header__prepend-icon
        order: 2

        +ltr()
          margin-right: 8px

        +rtl()
          margin-left: 8px

.v-list--nav
  padding-left: 15px
  padding-right: 15px

.v-list-item__icon
  margin: 12px 0
</style>
<style lang="scss" scoped>
.bottom {
  color: #fff;
  font-size: 12px;
  padding: 12px 0;
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;

  a {
    margin-right: 5px;
    margin-left: 5px;
    float: left;
    color: #ffffff;
    text-decoration: none;

    &::before {
      content: '|';
      color: '#fff';
      margin-right: 7px;
    }

    &:first-child {
      &::before {
        content: '';
        margin-right: 0px;
      }
    }
  }
}
</style>
<style lang="scss">
.theme--dark.v-list-item--active:hover::before, .theme--dark.v-list-item--active::before {
  opacity: 0 !important;
}

</style>
<style lang="scss" scoped>
.drawer{
  .link{
    text-decoration: underline;
    cursor: pointer;
  }
}
.extraItemContainer {
  height: 48px;
  line-height: 48px;
  cursor: pointer;
  border-radius: 4px;

  &:hover {
    background: rgb(194 190 190 / 12%);
  }

  .extraItem {

  }


}


</style>
